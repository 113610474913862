
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    text: String,
  },
})
export default class BoxWithBtn extends Vue {}
