
import { Options, Vue } from "vue-class-component";
import { kMobileNewsRWGS } from "@/config/newsData";
import BoxWithNews from "@/components/PC/BoxWithNews.vue";

@Options({
  components: {
    BoxWithNews,
  },
  data() {
    return {
      selectedYear: 0,
      info: kMobileNewsRWGS,
    };
  },
  methods: {
    onClickSwith(index: number) {
      if (this.selectedYear !== index) {
        this.selectedYear = index;
      }
    },
  },
})
export default class CharacterStory extends Vue {}
