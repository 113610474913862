export const kMobileNewsLatest = {
  latest: {
    title: "最新动态",
    news: [
      {
        "year": "2023",
        "title": "母校联动 | 蒋宪彬校友出席南昌大学新闻与传播学院2023届毕业典礼",
        "date": "6月12日",
        "mounth": "6",
        "conver": "https://gn-rrd.gnso.cn/2023/06/13/FuJj5lGaV6p6Z6HDlBRdBcKGA7Z3.png",
        "abstract": "2023年6月6日，南昌大学上海校友会浦东分会执行会长——蒋宪彬校友受邀返校参加新闻与传播学院2023届毕业典礼暨校友奖学金颁奖仪式。新闻与传播学院全体领导、系主任、教授代表、学生家长代表以及全体2023届本、硕、博毕业生共同参与了此次毕业盛典。典礼上，蒋宪彬校友作为嘉宾，在仪式上发表重要讲话。",
        "link": "https://mp.weixin.qq.com/s/5NdlctPYb0t4ctrHfW9eew"
      },
      // {
      //   "year": "2021",
      //   "title": "玩转社交电商的“道”与“术”（归农老蒋在华理工MBA课程演讲内容整理）",
      //   "mounth": "4",
      //   "date": "4月12日",
      //   "conver": "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/news/new01.png",
      //   "abstract": "从13人裂变到720万会员，归农创下了社交电商的发展奇迹。社交电商近几年非常“时髦流行”，在这个领域出现了许多传奇故事，但，社交电商底层逻辑到底是什么呢？用什么方法与心态，才能把这件事做好呢？2021年4月7日，归农创始人、天下商邦董事局主席、上海泰和商会会长蒋宪彬受邀在华东理工大学MBA课堂，就归农发展案例，发表了主题为《玩转社交电商的“道”与“术”》的演讲。",
      //   "link": "https://mp.weixin.qq.com/s/hQVDGHXMNQm05rHfdCvUzQ"
      // },
      {
        "year": "2021",
        "title": "南昌大学校长周创兵等领导亲切会见归农集团董事长蒋宪彬一行",
        "date": "3月8日",
        "mounth": "3",
        "conver": "http://news.ncu.edu.cn/images/2021-03/e7838725a70a47d9bda7c0dd256ea03b.jpeg",
        "abstract": "2021年，值南昌大学百年华诞。3月6日，归农集团创始人、董事长蒋宪彬作为南昌大学优秀校友返校，受邀出席“致新讲坛”校友话成才活动并发表演讲。南昌大学校长周创兵、新传学院院长陈信凌、新传学院书记程志红 、校友办主任黄海啸、学工处李飞强主任等学校领导亲切接见蒋宪彬一行。",
        "link": "https://mp.weixin.qq.com/s/w28ONnYOWQKktD7QhEPOJw"
      },
      // {
      //   "year": "2021",
      //   "title": "若同行，必不负！| 2020年，我们在归农跨年",
      //   "date": "1月13日",
      //   "mounth": "1",
      //   "conver": "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/news/new03.png",
      //   "abstract": "2020年是举国同心、直面挑战的一年！每颗熬过冬天的种子，终将会迎来一个春暖花开。在这个被称为“最糟糕的一年”，归农，稳定并继续呈上升态势，天下商邦，找到深耕发展之路。照惯例，每年年末，归农都有一场重要的线下聚会。2020年12月31日，自归农创立以来，我们迎来第5次年终相聚！线下聚会，意味着什么？如归农会员所说：在归农，线下聚，有景，有情，有义！让你难忘的一定是那些有温度的人和故事！",
      //   "link": "https://mp.weixin.qq.com/s/xCh8xj2R4BodtoyVGpFA7g?"
      // },
      // {
      //   "year": "2021",
      //   "title": "500人进金川，为什么他们能享受警车开道的待遇？",
      //   "date": "4月02日",
      //   "mounth": "4",
      //   "conver": "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/news/new02.png",
      //   "abstract": "2017年，归农推出的金川“蕙芷庄园”雪梨膏，成为一款爆款产品，走进千家万户。因其独特的品质功效广受会员喜爱，给金川当地带来了良好的经济社会效应，受到社会各界和媒体的广泛关注。每年3月是金川“梨花节”，归农都会举行一年一度的金川聚会之行，带领归农会员躬身入局，亲身为金川雪梨膏见证把关。而今年的3月19日至22日，对于500位“归蜜”来说，是一段格外令人难忘的四天三夜。因为恶劣的地质条件，通往金川的道路并不罗曼蒂克，甚至充满颠簸、艰辛、疲惫……500位“归蜜”从全国各地汇聚，11辆大巴从成都出发，一路穿越高原河谷，经历了海拔及气候的巨大变化，因为下雨、泥石流、坍塌，路途上被困了十多个小时……跨越千山万水，往返上千公里，这一路又发生了哪些令人回味的故事？又有哪些令人难忘的时刻？归蜜们又有何新的收获？",
      //   "link": "https://mp.weixin.qq.com/s/odd9XNVJCz7UYS-ERS72Pg"
      // },
      {
        "year": "2020",
        "title": "一场地方特产直播，观看人数破50万，7天内的所有乌鸡蛋被卖光",
        "date": "2020-12-11",
        "mounth": "12",
        "conver": "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/news/wj.png",
        "abstract": "12月10日，江西泰和县委正县级干部做客归农直播间，面向660万归农会员，直播推介中国原种乌鸡—泰和乌鸡系列产品。本次直播观看人数破50万，点赞量超过百万。这是继7月3日，开封祥符区区长直播卖沙地蜜薯观看人数创20万以来，再次创历史记录！",
        "link": "https://mp.weixin.qq.com/s/hQVDGHXMNQm05rHfdCvUzQ"
      },
      {
        "year": "2020",
        "title": "在云南元江有一种植物被称为“神草”，究竟是什么草？",
        "date": "2020-12-08",
        "mounth": "12",
        "conver": "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/index/yjlh.png",
        "abstract": "2020年11月27日-11月30日232名归蜜从全国各地赶来，齐聚云南元江！有超过90%的归蜜，第一次来到元江只为一睹亚洲最大的万亩芦荟基地的风采见证一棵芦荟引发的精彩人生",
        "link": "https://mp.weixin.qq.com/s/b31Y9-mzQzlitZGVzJpYxw"
      }
    ]
  }
}

export const kMobileNewsInfo = {
  pbry: {
    title: "品牌荣誉",
    des: "“农为邦本，本固邦宁”， 2021年，是归农的第7年， 归农积极响应并践行“乡村振兴”国家战略， 已经取得了显著的经济效应与社会效应， 得到用户、公众、政府、新闻媒体的高度认可。",
    children: [
      {
        year: "2021",
        children: [
          {
            type: 'img',
            subtitle: '品牌荣誉',
            img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/official-website/pinpairongyu/3.jpg",
          },
          {
            type: 'detailBox',
            subtitle: "内容摘要",
            ps: [
              `2021年归农荣膺“中国履行社会责任杰出企业”。`,
            ]
          },
        ]
      },
      {
        year: "2020",
        children: [
          {
            type: 'img',
            subtitle: '品牌荣誉',
            img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/official-website/pinpairongyu/1.jpg",
          },
          {
            type: 'detailBox',
            subtitle: "内容摘要",
            ps: [
              `2020年蒋宪彬个人荣膺“中国农牧行业功勋企业家”殊荣。`,
            ]
          },
        ]
      },
      {
        year: "2019",
        children: [
          {
            type: 'img',
            subtitle: '品牌荣誉',
            img: require("@/assets/news/2019.png"),
          },
          {
            type: 'detailBox',
            subtitle: "内容摘要",
            ps: [
              `2019年底，在归农年度盛典上，由著名词曲作家陈小奇创作的原创歌曲《归农》及MV正式推出。`,
            ]
          },
        ]
      },
      {
        year: "2018",
        children: [
          {
            type: 'img',
            subtitle: '品牌荣誉',
            img: require("@/assets/news/2018.png"),
          },
          {
            type: 'detailBox',
            subtitle: "内容摘要",
            ps: [
              `2018年12月，第四届中国农业品牌年度颁奖盛典在成都举行。归农荣获“改革开放40年中国农业十大互联网品牌”。`,
            ]
          },
        ]
      },
      {
        year: "2017",
        children: [
        ]
      },
      {
        year: "2016",
        children: [
        ]
      },
      {
        year: "2015",
        children: [
          {
            type: 'img',
            subtitle: '品牌荣誉',
            img: require("@/assets/news/2015.png"),
          },
          {
            type: 'detailBox',
            subtitle: "内容摘要",
            ps: [
              `2015“中国农牧行业品牌营销创新论坛”在成都召开`,
              `归农旗下品牌——“蕙芷庄园”荣获“2015年中国农牧行业十大创新品牌”。`,
            ]
          },
        ]
      }
    ]
  },
  mtxw: {
    title: "媒体新闻",
    news: [
      // {
      //   "year": "2022",
      //   "mounth": "09",
      //   "date": "2022-09-23",
      //   "title": "【又是一年丰收时】泰和：秋收稻谷笑开颜 黑米飘香迎丰收",
      //   "abstract": "江西归农村，种植黑米，统一采种，统一管理，统一包销。助力乡村振兴，获得政府和媒体高度关注和赞赏。",
      //   "conver": "https://gn-rrd.gnso.cn/2022/09/23/FinJE-lBV39p7qAXnCAmTsC_kf-d.png",
      //   "link": "https://share.jxgdw.com/share/media/866539"
      // },
      {
        "year": "2022",
        "mounth": "07",
        "date": "2022-07-20",
        "title": "“两对三提”｜灌溪镇：乡村振兴大讲堂 凝聚发展精气神",
        "abstract": "为巩固拓展脱贫攻坚成果同乡村振兴有效衔接，着力提升基层干部服务乡村振兴能力和水平，打造乡村振兴理论与实践相结合的大平台，灌溪镇举办“乡村振兴大讲堂”第一期在塅坑村归农生态园精彩开讲。",
        "conver": "https://gn-rrd.gnso.cn/2022/07/21/FtSDtfYpUjPB8Fm0gVFK_6gRL15u.png",
        "link": "http://taihe.yun.jxntv.cn/p/43242.html"
      },
      {
        "year": "2022",
        "mounth": "06",
        "date": "2022-06-17",
        "title": "上海泰和商会慰问我县援沪医疗队",
        "abstract": "为弘扬伟大的抗疫精神，歌颂医疗人员的使命与担当，向医务工作者表达致敬。6月16日下午，上海泰和商会慰问泰和县援沪医疗队活动在县人民医院举行。活动由县工商联主席彭建林主持。县委常委、统战部长夏得烈，县政府副县长肖红，县委统战部副部长、工商联党组书记翁祥斌，上海泰和商会会长蒋宪彬，县卫健委、县人民医院、县中医院、县妇幼保健院主要负责人及县援沪医疗队全体人员参加了此次活动。",
        "conver": "https://gns1.wxrrd.com/2022/06/17/FoSchaM8A_o9guxJDd9t1PhPd5fo.jpg",
        "link": "https://mp.weixin.qq.com/s/uYBlUxrDZLOOmsaV_GE9-A"
      },
      // {
      //   "year": "2022",
      //   "mounth": "03",
      //   "date": "2022-03-14",
      //   "title": "【杰出乡贤】泰和县灌溪镇蒋宪彬：为乡村产业振兴插上“互联网”翅膀",
      //   "abstract": "蒋宪彬，男，1978年10月出生，泰和县灌溪人，2003年毕业于南昌大学新闻与传播学院，现任归农集团董事长、天下商邦董事局主席、上海泰和商会会长、泰和县乡贤联谊会副会长、灌溪镇乡贤理事会常务副会长。应邀担任腾讯电商学院讲师、《商界》、《中国服饰报》等行业专刊特邀撰稿人。",
      //   "conver": "https://gns1.wxrrd.com/2022/06/17/FoMq89DbbxX4boWFcZWJGA8sTpq7.png",
      //   "link": "https://wfx.gnso.cn/article/detail/69500"
      // },
      {
        "year": "2022",
        "mounth": "05",
        "date": "2022-05-30",
        "title": "社交电商2.0：左手乡村振兴，右手社群迭代 | 品牌新事",
        "abstract": "我们唯有通过与他人结合，方能实现自我，“个别我”（I）需要结合“团体我”（We），方能建立真正“完整的个人我”（Fully I）。",
        "conver": "https://gns1.wxrrd.com/2022/06/13/Fr7Xrfw_YLSBb73zD0SlbMzqrrie.jpg",
        "link": "https://mp.weixin.qq.com/s/8nogcmkc39DFxVoqTUKjKg"
      },
      {
        "year": "2021",
        "mounth": "03",
        "date": "2021-03-25",
        "title": "校友话成才——归农集团董事长蒋宪彬来我院分享创业故事",
        "abstract": "3月6日下午，在迎接南昌大学一百周年校庆之际，校友、归农集团董事长蒋宪彬回到母院，并在“致新讲坛”做了一场回顾自己20年创业的精彩故事分享。报告会在人文楼A143举行，南昌大学新闻与传播学院院长陈信凌教授、院党委书记程志红出席，部分本科生、研究生同学到场参加。2020年，为迎接母校百年校庆，蒋宪彬携夫人捐赠了100万人民币。",
        "conver": "https://ms.wxrrd.com/2021/07/23/FmjzLls05fXnImipRSQUoPeTZzpj.jpg",
        "link": "http://xgc.ncu.edu.cn/yxfc/affebcf290024d3ab66f38b24610cf9e.htm"
      },
      {
        "year": "2021",
        "mounth": "02",
        "date": "2021-02-06",
        "title": "下沉市场要回归农业 也要创新技术",
        "abstract": " “基于农户销售平台的利益共生模式”——这是归农电商探索出来的一条可持续发展道路。在消费端，归农电商会推广一些深加工农产品，不是番茄、土豆等原始农产品，而是靠推荐来唤醒需求的产品，建立认知壁垒。而在生产端，则从源头寻找优质农产品基地，创新农产品模式，形成独家品牌。",
        "conver": "https://ms.wxrrd.com/2021/07/23/Fjk3Q24MFR97uS39JGIgfUx4v1ZI.jpg",
        "link": "http://dzb.whb.cn/2021-02-06/8/detail-714006.html"
      },
      {
        "year": "2020",
        "mounth": "12",
        "date": "2020-12-01",
        "title": "四川金川：产业重构 古树雪梨焕新机",
        "abstract": "四川阿坝金川县拥有古树雪梨超过4万亩，年产雪梨接近3万吨。可是由于鲜果附加值低、再加上离大城市远、人工成本高等原因，很长一段时间，这些雪梨并没有给当地的农民带来好收益，大部分雪梨最后只能是烂在林下。社交电商为雪梨滞销提供了一条出路。央视报道中出现的归农金川雪梨膏这一爆款产品，让当地的老百姓吃上了雪梨饭。",
        "conver": "https://ms.wrcdn.com/2020/12/04/FpMd7V2dBsNm5D1aENtRCrmgk23_.jpg",
        "link": "https://tv.cctv.com/2020/12/01/VIDEQoeV1FRxO8BHbSIJGvnp201201.shtml?spm=C50612547996.PlFNCDWAzldR.0.0",
      },
      {
        "year": "2020",
        "mounth": "12",
        "date": "2020-12-01",
        "title": "归农创始人蒋宪彬荣膺“2020中国农牧行业功勋企业家”",
        "abstract": `2020年11月12日-14日，第十七届AWA大赢家(海南)国际论坛在海南举行。归农在四川金川、河南开封、江西泰和、新疆拜城、云南元江、甘肃民勤等多个地区，助推农产品销售，屡创佳绩，为农业创新发展创造了巨大的经济价值与社会效益，论坛组委会授予归农集团董事长蒋宪彬先生“2020年度中国农牧行业功勋企业家” 称号。`,
        "conver": "https://gns1.wxrrd.com/2022/07/08/FhgVaROY3YRr9vnV--OR4S7rLXEx.png",
        "link": "https://caijing.chinadaily.com.cn/a/202011/30/WS5fc476b8a3101e7ce9732566.html",
      },
      {
        "year": "2020",
        "mounth": "01",
        "date": "2020-01-21",
        "title": "电商行业整体下沉，归农电商挖掘新鲜商机",
        "abstract": `为什么电子商务投资偏爱土味的农村电商领域？归农电商创始人蒋宪彬表示，“归农通过实地考察调研，将优质产品的生产知识、历史故事、人文情怀等所有真实内容，包括实地“鉴证”的活动现场，制作成“有料有趣有爱”的图片视频文字等内容，在移动社交平台上广泛传播，丰富透明的信息，会让人们因阅读而打动、而喜欢。`,
        "conver": "https://ms.wrcdn.com/2020/03/18/FqhgiBKOrvR7z23wDXB6GtIgTYCE.jpg",
        "link": "http://hunan.ifeng.com/a/20200121/8101481_0.shtml?from=timeline",
      },
      {
        "year": "2020",
        "mounth": "01",
        "date": "2020-01-14",
        "title": "一年消化600多万公斤金川雪梨，一分钟销售5500盒！——用市场推动农场 归农探索乡村振兴之道",
        "abstract": `2020年1月4日，2019年“归农”年会在成都举行。发布会上，归农电商创始人蒋宪彬透露了一组助农兴农数据。台下，四川省阿坝州金川县金雪梨果业公司董事长杜宇笑得格外灿烂，金川县副县长也到场支持。`,
        "conver": "https://ms.wrcdn.com/2020/10/30/FpgDGLCWqXbsySQVFq0XWs8ZSExt.jpg",
        "link": "https://baijiahao.baidu.com/s?id=1655683535104887765&wfr=spider",
      },
      {
        "year": "2020",
        "mounth": "01",
        "date": "2020-01-08",
        "title": "归农，颠覆电商模式，促进乡村振兴",
        "abstract": `从商业的角度而言，归农担当的是内容电商平台的角色，它搭建起了农户、消费者与归农之间的商贸平台，让丰收的农产品实现畅销，让消费者得到真正物美价廉的农产品；从品牌塑造层面观察，归农是农产品上市前的把关者，它的品牌理念倡导“真实高于一切，一切源于天然”，试图把安全健康、品质上乘、天然天然的农产品推荐给更多的消费者；从情感境界来看，归农还是很多人追求理想、实现目标、传递情感的渠道。`,
        "conver": "https://ms.wrcdn.com/2020/10/30/FtfRFn2Mhbvr3jSnPpF5jEMh8qGo.jpg",
        "link": "https://new.qq.com/omn/20200108/20200108A09OX000.html",
      },

      {
        "year": "2020",
        "mounth": "01",
        "date": "2020-01-05",
        "title": "归农如何用内容电商模式颠覆传统商业逻辑",
        "abstract": `用社交电商的方式，用创新的内容电商，让上下游信息对称起来，也可以在源头上，改变农业，打造新农人、新农村和新农业。`,
        "conver": "https://ms.wrcdn.com/2020/10/30/FmUdgupBo_Rhi2qi30gxC3JBW2_8.jpg",
        "link": "https://m.sohu.com/a/364887459_465566/?pvid=000115_3w_a",
      },
      // {
      //   "year": "2019",
      //   "mounth": "12",
      //   "date": "2019-12",
      //   "title": "助农兴农在行动， 2019年归农年会盛典举行",
      //   "abstract": `如今各类电子商务平台如雨后春笋般冒了出来。互联网+农业、农村电商、农村淘宝等新鲜事物不断出现在人们的视野里。2020年1月4日，归农在成都举行了助农兴农2019归农年会暨天下商邦发布会。长期以来，归农探索解决农业产销结构性矛盾的创新商业模式，解决农产品滞销问题。`,
      //   "conver": "https://ms.wrcdn.com/2020/10/30/Fp1DjEjVJbpp4ckpBycO5HPeMG3K.jpg",
      //   "link": "https://v.qq.com/x/page/h3165zsc2xy.html",
      // },
      {
        "year": "2020",
        "mounth": "01",
        "date": "2020-01-04",
        "title": "一年消化600多万公斤！社群电商助力，金川雪梨从拦在枝头变全网大卖",
        "abstract": `播为契机、以品质把控为核心，在一个个归农用户群中，金川雪梨膏销售规模迅速实现“裂变式”增长。至今，仍是归农商城卖得最好的产品之一。`,
        "conver": "https://ms.wrcdn.com/2020/03/18/FphDvX6S_JqjIvWgyYUprOYqbFDJ.jpg",
        "link": "https://baijiahao.baidu.com/s?id=1654800509846303363&wfr=spider",
      },
      {
        "year": "2016",
        "mounth": "06",
        "date": "2016-06-15",
        "title": "横峰干部“充电”月月精彩",
        "abstract": `2016年，归农创始人蒋宪彬应江西横峰县委组织部邀请，给全体干部、当地企业家做新知识讲座。`,
        "conver": "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Foss.huangye88.net%2Flive%2Fuser%2F2450149%2F1582174270082512600-0.jpg%404e_1c_350w_350h_90Q%7Cwatermark%3D1%26t%3D50%26object%3Dd2F0ZXIucG5nQDMwUA&refer=http%3A%2F%2Foss.huangye88.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642926941&t=818b56ea41c6c3f691cbe7c7ef5ef047",
        "link": "https://www.sohu.com/a/83463084_362103",
      },
    ]
  }
}

export const kMobileNewsRWGS = [
  {
    "title": "归蜜故事",
    "news": [
      { "title": "深夜对话，意外走红，居然是个漂亮主播！还有不为人知的心酸故事……", "date": "2021-01-03", "mounth": "1", "conver": "https://hzno.oss-cn-beijing.aliyuncs.com/news/whs.png?versionId=CAEQGxiBgID1jMu32hciIDY3ZjZjMGNiZmQ3NjQwYTliNDMzNjIyMTM4YTY4NGMw", "abstract": "从单亲妈妈到漂亮主播,从耀眼的明星到回归家庭的小女人，从痛失爱人的极度绝望到如今归农的充实快乐，“命运以痛吻我，我却报之以歌”王惠用坚韧不拔切换着人生的不同频道。", "link": "https://mp.weixin.qq.com/s/w--Jud5KpQYO1cuXQaV_Cw" },
      { "title": "烧不死的鸟是凤凰：一名女医生遭遇婚变后的浴火重生", "date": "2020-12-24", "mounth": "12", "conver": "https://hzno.oss-cn-beijing.aliyuncs.com/news/bsfh.png?versionId=CAEQGxiBgIDDmtW32hciIGMxOTBjZDhlNWZkNzQ3ZjA4YjMzNzcxYjA0MWUyNGIx", "abstract": "2020年11月，一个凌晨1点多的冬日深夜，归农一位名为“凡珺”的归蜜，主动给归农集团总裁涛哥发了一段私信。涛哥原本即将休息，而看到这几段信息后，也无法睡去，沉思片刻，开始聊了起来，两人一直聊到凌晨3点多。是什么让她难以入眠，想要与涛哥一吐为快、尽兴分享？涛哥又是被什么给触动聊至深夜？", "link": "https://mp.weixin.qq.com/s/hlCPIHrvGzhhpUwLwBulcA" },
      { "title": "76岁老太太成“网红”，600万人喊她“习婆婆”", "date": "2020-10-21", "mounth": "10", "conver": "https://hzno.oss-cn-beijing.aliyuncs.com/news/cpp.png?versionId=CAEQGxiBgID8zJK42hciIDNlYWExZGU1Y2M2MDRkMzQ4ZjRhZTY2NGY0NDI4YTM3", "abstract": "很多人都渴望着变成“网红”，各种豁出去，各种努力，终究都没“红”。这里却有一个76岁的老太太，一不小心成了“网红”，曾两度被评为“归农社群十大红人”。她拍的照片，她画的画，在无数个微信群、无数人的朋友圈里转发分享。她在电音节蹦迪狂欢，上千人围着她嗨，成为全场焦点，她被600多万人称为“归农习婆婆”。", "link": "https://mp.weixin.qq.com/s/tt5G602co8L2zGKgT9Wrjg" },
      { "title": "葛家芬：国企高管退休，为什么会迷上这样的“微商” ？", "date": "2020-10-14", "mounth": "10", "conver": "https://hzno.oss-cn-beijing.aliyuncs.com/news/gjf.png?versionId=CAEQGxiBgICghuy32hciIDZjY2ZkMWFjYmNlOTQwNTFhYTQ4NWFlZDkwN2Q4MzE0", "abstract": "葛姐住豪宅、开奔驰，做过国营企业高管、事业单位局长，满足了吗？骄傲了吗？没有！她不差钱，但依然在不断挑战新生事物，在归农重新出发、迅速崛起，几个月时间就实现了“月入过万”，她在群里写的“每日总结”，被无数人转发、点赞，成为归农社群里面的一个“网红”级人物。", "link": "https://mp.weixin.qq.com/s/SJntrnk8dSxJ8OTNIkEa3Q" },
      { "title": "刘丹：体操冠军/军嫂/直销难民/微商…，她的精彩人生给新女性打了一个样", "date": "2020-05-21", "mounth": "10", "conver": "https://hzno.oss-cn-beijing.aliyuncs.com/news/ld.png?versionId=CAEQGxiBgIDrvPO32hciIDFkZmJiNDA4YTNjZTQ3MDE5ZTk5ZDQ5MWQ2ZDRjZWQw", "abstract": "作为学生，参赛拿过体操冠军；作为老师，带领学生斩获无数个一等奖；作为军嫂，分居11年坚守着爱情；做直销、做微商，每次亏钱几十万，仍不言败，不断从头再来。她，就这么要强，屡败屡战，折腾不止…，直到遇见归农，感悟生命生活，遇见美好。她说：亲情爱情友情是这世间弥足珍贵的情感，时间汹涌，好好陪伴爱我和我爱的人吧，我们好好去体会和读懂她…", "link": "https://mp.weixin.qq.com/s/T-dOZAJj1FErvD9-_AqIdw?" },
      { "title": "曾的：给爸爸的情书", "date": "2020-05-21", "mounth": "10", "conver": "https://hzno.oss-cn-beijing.aliyuncs.com/news/erjie.png?versionId=CAEQGxiBgMDk9_232hciIGJiNTRkZmZkZjFlMzQzNTY5MzZlM2JmNTg4OWFjMmMz", "abstract": "“ 正是因为我爸的这一句话，奠定了我在归农长期消费的基础，也为我后来分享归农有了底气，我为有爱我的父亲和有这样一个有开放思维的父亲感到自豪，感到骄傲️ ！”", "link": "https://mp.weixin.qq.com/s/z2vdQ3rGsBcRCgaqx_G9Lg" },
    ]
  }
]